import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
  root: {
  },
});

class Notes extends Component {

  render() {
    const { customer } = this.props;

    let notes;
    if (customer.notes && customer.notes.length > 0) {
      notes = customer.notes.split('\n').map(function(item, key) {
        return (
          <ListItem key={key}>
            <ListItemText secondary={item} />
          </ListItem>
        )
      })
    } else {
      notes = <ListItem style={{paddingTop: 0, marginBottom: 15}}>
        <ListItemText secondary="None" />
      </ListItem>
    }

    return (
      <List dense component="nav">
        {notes}
      </List>
    )

  }

}

export default withStyles(styles)(Notes);
