import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
  root: {
  },
});

class Information extends Component {

  render() {
    const { customer } = this.props;

    var address = [
      customer.address_1,
      customer.address_2,
      customer.address_3,
      customer.address_4,
      customer.address_postcode,
    ];
    address = address.filter(x => !!x).join(', ');

    var invoice_address = [
      customer.invoice_name,
      customer.invoice_company,
      customer.invoice_address_1,
      customer.invoice_address_2,
      customer.invoice_address_3,
      customer.invoice_address_postcode,
      customer.invoice_number,
      customer.invoice_email,
    ];
    invoice_address = invoice_address.filter(x => !!x).join(', ');

    var delivery_address = [
      customer.delivery_name,
      customer.delivery_company,
      customer.delivery_address_1,
      customer.delivery_address_2,
      customer.delivery_address_3,
      customer.delivery_address_postcode,
      customer.delivery_number,
      customer.delivery_email,
    ];
    delivery_address = delivery_address.filter(x => !!x).join(', ');

    return (
      <List component="nav">
        <ListItem>
          <ListItemText primary='Address' secondary={address} />
        </ListItem>
        <ListItem button component='a' href={'https://online.smartdebit.co.uk/Payers/Details?Pslid=cloudnet&ReferenceNumber=' + customer.dd_ref} target="_blank">
          <ListItemText primary='Direct Debit Reference' secondary={customer.dd_ref} />
        </ListItem>
        <ListItem button component='a' href={'http://pbx.birchills.net/c/' + customer.sipcentric_id} target="_blank">
          <ListItemText primary='Sipcentric ID' secondary={customer.sipcentric_id} />
        </ListItem>
        <ListItem>
          <ListItemText primary='Invoice Address' secondary={invoice_address} />
        </ListItem>
        <ListItem>
          <ListItemText primary='Delivery Address' secondary={delivery_address} />
        </ListItem>
        <ListItem>
          <ListItemText primary='Last Updated' secondary={new Date(customer.last_updated).toLocaleDateString()} title={customer.last_updated} />
        </ListItem>
        <ListItem>
          <ListItemText primary='Date Added' secondary={new Date(customer.date_added).toLocaleDateString()} title={customer.date_added} />
        </ListItem>
      </List>
    )

  }

}

export default withStyles(styles)(Information);
