import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  table: {
    width: '100%',
  },
});

class Subscriptions extends Component {

  render() {
    const { classes, subscriptions, total } = this.props;

    return (
      <div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptions.map(subscription => {
              return (
                <TableRow key={subscription.id}>
                  <TableCell title={subscription.id}>{subscription.description}</TableCell>
                  <TableCell>{subscription.quantity}</TableCell>
                  <TableCell title={subscription.price}>
                    {new Intl.NumberFormat('en-GB', {
                      style: 'currency',
                      currency: 'GBP'
                    }).format(subscription.price)}
                  </TableCell>
                  <TableCell title={subscription.total}>
                    {new Intl.NumberFormat('en-GB', {
                      style: 'currency',
                      currency: 'GBP'
                    }).format(subscription.total)}
                  </TableCell>
                  <TableCell title={subscription.from_date}>{new Date(subscription.from_date).toLocaleDateString()}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell colSpan={3} />
              <TableCell title={total}>
                {new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: 'GBP'
                }).format(total)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    )

  }

}

export default withStyles(styles)(Subscriptions);
