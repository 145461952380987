import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import Confetti from 'react-dom-confetti';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Search from '../Search';
import Status from '../Status';

const styles = theme => ({
  root: {
    width: '100%',
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: 20,
  },
});

const config = {
  angle: 240,
  spread: 45,
  startVelocity: 45,
  elementCount: 50,
  decay: 0.9
};

class Menu extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      confetti: false,
      count: 0,
    };

    this.toggleLights = this.toggleLights.bind(this);
    this.clearClick = this.clearClick.bind(this);
    this.setCount = this.setCount.bind(this);
  }

  toggleLights() {
    this.props.toggleLights();
  }

  clearClick() {
    this.setState({confetti: true});

    setTimeout(() => {
      this.setState({confetti: false});
    }, 5000);
  }

  setCount(e) {
    this.setState({count: e});
  }

  render() {
    const { classes, lightsOn } = this.props;
    const { confetti, count } = this.state;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Typography className={classes.title} variant="h6" color="inherit" noWrap>
              Better BIP
            </Typography>
            <Search setCount={this.setCount} />
            <Status count={count} />
            <Confetti active={confetti} config={config}/>
            <Button color="inherit" component={Link} to="/" onClick={this.clearClick}>Clear</Button>
            <Switch
              checked={lightsOn}
              onChange={this.toggleLights}
              color="default"
              title="Lights"
            />
          </Toolbar>
        </AppBar>
      </div>
    );
  }

}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Menu);
