import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import Paper from '@material-ui/core/Paper';
import DocumentTitle from 'react-document-title';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Header from '../Header';
import Subscriptions from '../Subscriptions';
import Information from '../Information';
import Notes from '../Notes';
import ToTop from '../ToTop';

const styles = theme => ({
  paper: {
    margin: '20px',
  },
  padding: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
  },
  progress: {
    margin: theme.spacing.unit * 3,
  },
});

class Customer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        customer: {
          company: "",
        },
      },
    };
  }

  fetchData() {
    const { enqueueSnackbar } = this.props;

    fetch(process.env.REACT_APP_API + '/customers/' + this.props.match.params.id)
      .then(response => response.json())
      .then(data => this.setState({ data }))
      .catch(() => enqueueSnackbar('Failed to fetch customer data', { variant: 'error' }));
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.fetchData();
    }
  }

  render() {
    const { data } = this.state;
    const { classes } = this.props;

    let header, subscriptions, information, notes, title;

    if (data.customer && data.subscriptions) {
      header = <Header customer={data.customer} />
      subscriptions = <Subscriptions subscriptions={data.subscriptions} total={data.subscriptions_total} />
      information = <Information customer={data.customer} />
      notes = <Notes customer={data.customer} />
      title = data.customer.company + ' - Better BIP';
    } else {
      header = subscriptions = information = notes = <CircularProgress className={classes.progress} />
    }

    return (
      <DocumentTitle title={title}>
        <div>
          <Paper elevation={1} className={classes.paper}>
            {header}
          </Paper>

          <Paper elevation={1} className={classes.paper}>
            <Typography variant="h5" component="h3" className={classes.padding}>
              Active Subscriptions
            </Typography>
            {subscriptions}
          </Paper>

          <Paper elevation={1} className={classes.paper}>
            <Typography variant="h5" component="h3" className={classes.padding}>
              Customer Records
            </Typography>
            {information}
          </Paper>

          <Paper elevation={1} className={classes.paper}>
            <Typography variant="h5" component="h3" className={classes.padding}>
              Notes
            </Typography>
            {notes}
          </Paper>
          <ToTop />
        </div>
      </DocumentTitle>
    );
  }

}

export default withSnackbar(withStyles(styles)(Customer));
