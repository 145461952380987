import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { init } from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production') {
  init({
    dsn: 'https://a91f664f4d2b489f9d8ef74051c1e3e8@sentry.sipcentric.com/9',
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
