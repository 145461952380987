import React, { Component } from 'react';
import { SnackbarProvider } from 'notistack';
import blue from '@material-ui/core/colors/blue';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import DocumentTitle from 'react-document-title';

import Menu from '../Menu';
import Home from '../Home';
import Customer from '../Customer';

const theme = {
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: blue,
  },
};

const themeLight = createMuiTheme({
  ...theme,
  palette: {
    ...theme.palette,
    type: 'light',
  },
});

const themeDark = createMuiTheme({
  ...theme,
  palette: {
    ...theme.palette,
    type: 'dark',
  },
});

const styles = theme => ({
  root: {
    width: '100%',
  },
  paper: {
    margin: '50px',
    width: '700px',
  },
});

class App extends Component {

  constructor(props) {
    super(props);

    this.toggleLights = this.toggleLights.bind(this);

    this.state = {
      lightsOn: false,
    };
  }

  componentDidMount() {
    this.setState({ lightsOn: JSON.parse(localStorage.getItem('lightsOn')) });
  }

  toggleLights() {
    const lightsOn = !this.state.lightsOn;
    this.setState({ lightsOn: lightsOn });
    localStorage.setItem('lightsOn', lightsOn);
  }

  render() {

    const { lightsOn } = this.state;

    return (
      <DocumentTitle title='Better BIP'>
        <Router>
          <SnackbarProvider maxSnack={3}>
            <MuiThemeProvider theme={lightsOn ? themeLight : themeDark}>
              <CssBaseline />
              <Menu toggleLights={this.toggleLights} lightsOn={lightsOn} />
              <Route exact path='/' component={Home} />
              <Route path='/customers/:id' component={Customer} />
            </MuiThemeProvider>
          </SnackbarProvider>
        </Router>
      </DocumentTitle>
    );
  }

}

export default withStyles(styles)(App);
