import React, { Component } from 'react';
import List from '@material-ui/core/List';
import Clipboard from 'react-clipboard.js';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
  shareLink: {
    marginLeft: theme.spacing.unit,
  },
  pullRight: {
    right: 0,
    position: 'absolute',
    margin: '20px',
  },
});

class Header extends Component {

  render() {
    const { classes, customer } = this.props;

    var name = [
      customer.contact_first_name,
      customer.contact_last_name,
    ]
    name = name.filter(x => !!x).join(' ');

    var contact = [
      customer.contact_number,
      customer.contact_email,
    ];
    contact = contact.filter(x => !!x).join(', ');

    const link = `${customer.company} (${name}) ${window.location.href}`;

    let legacy_pricing_id = 1047;
    let current_pricing_id = 1057;

    let pricingChip;

    if (customer.id >= current_pricing_id) {
      pricingChip = <Chip label="Current Pricing" className={classes.pullRight} color="primary" variant="outlined" />
    } else if (customer.id <= legacy_pricing_id) {
      pricingChip = <Chip label="Legacy Pricing" className={classes.pullRight} color="secondary" variant="outlined" />
    }

    if (pricingChip) {
      pricingChip = <Tooltip placement="left" title="Use only as a guideline">{pricingChip}</Tooltip>
    }

    return (
      <List component="nav">
        {pricingChip}
        <ListItem>
          <Tooltip placement="top-start" title={customer.id}>
            <Typography variant="h5" component="h3">{customer.company}</Typography>
          </Tooltip>
          <Clipboard data-clipboard-text={link} component="a" className={classes.shareLink}>
            <Tooltip placement="right" title="Copy share link">
              <IconButton color="primary">
                <LinkIcon />
              </IconButton>
            </Tooltip>
          </Clipboard>
        </ListItem>
        <ListItem>
          <ListItemText primary={name} secondary={contact} />
        </ListItem>
        <ListItem>
          <Button variant="outlined" color="primary" target="_blank" href={'http://pbx.birchills.net/c/' + customer.sipcentric_id}>Manager</Button>
        </ListItem>
      </List>
    )

  }

}

export default withStyles(styles)(Header);
