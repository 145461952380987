import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginLeft: "10px",
  },
  chip: {
    color: "#fff",
    borderColor: "rgba(255, 255, 255, 0.23)",
  }
});

class Status extends Component {

  render() {

    const { classes, count } = this.props;

    if (count > 0) {
      return (
        <div className={classes.root}>
          <Tooltip title="Records loaded" placement="right">
            <Chip
              label={count}
              variant="outlined"
              className={classes.chip}
            />
          </Tooltip>
        </div>
      )
    } else {
      return (
        <div className={classes.root}>
          <CircularProgress
            variant="indeterminate"
            color="secondary"
          />
        </div>
      )
    }

  }

}

export default withStyles(styles)(Status);
