import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: '20px',
    width: '700px',
  },
});

class Home extends Component {

  render() {
    const { classes } = this.props;

    return (
      <Paper elevation={1} className={classes.root}>
        <Typography variant="h5" component="h3">
          Use the search above
        </Typography>
      </Paper>
    );
  }

}

export default withStyles(styles)(Home);
