import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const styles = theme => ({
  root: {
    margin: '50px',
  },
});

class ToTop extends Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    window.scrollTo(0, 0);
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container justify="center">
        <Button variant="fab" color="primary" aria-label="Add" className={classes.root} onClick={this.handleClick}>
          <ArrowUpwardIcon />
        </Button>
      </Grid>
    );
  }

}

export default withStyles(styles)(ToTop);
